import { clone, cloneDeep, intersection, isEqual, merge } from "lodash"
import { trimZeroAfterPoint } from "./test/common_logic"

export const ConditionalWrapper = ({
  condition,
  wrapper,
  elseWrapper = wrapChildren => wrapChildren,
  children,
}) => (condition ? wrapper(children) : elseWrapper(children))

export const isLocalLink = path => {
  return (
    path &&
    !path.startsWith("http://") &&
    !path.startsWith("https://") &&
    !path.startsWith("//")
  )
}

export const formDataEncode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

export const isSSR = () => {
  return typeof window === "undefined"
}

export const scrollToElement = element => {
  if (element) {
    element.scrollIntoView({ behavior: "smooth" })
  }
}

export const getFileNameFromPath = path => {
  return path.replace(/^.*[\\/]/, "")
}

export const displayUnit = (data, displayUnit) => {
  let formattedUnit = ""

  if (data.unit) {
    if (typeof data.unit === "string") {
      formattedUnit = data.unit
    } else {
      formattedUnit = data.unit[displayUnit]
    }

    formattedUnit = ` (${formattedUnit})`
  }

  return formattedUnit
}

export function downloadCSV(file_name, content) {
  const csvData = new Blob([content], { type: "text/csv" })
  const a = document.createElement("a")
  document.body.appendChild(a)
  a.style = "display: none"
  a.href = URL.createObjectURL(csvData)
  a.download = file_name
  a.click()
  URL.revokeObjectURL(a.href)
  a.remove()
}

export const clip = (val, min, max) => Math.min(max, Math.max(min, val))

export class rgb {
  constructor(red, green, blue) {
    this.red = red
    this.green = green
    this.blue = blue
  }

  minus(other) {
    return new rgb(
      this.red - other.red,
      this.green - other.green,
      this.blue - other.blue
    )
  }

  plus(other) {
    return new rgb(
      this.red + other.red,
      this.green + other.green,
      this.blue + other.blue
    )
  }

  factor(scale) {
    return new rgb(this.red * scale, this.green * scale, this.blue * scale)
  }

  length() {
    return Math.sqrt(this.red ** 2, this.green ** 2, this.blue ** 2)
  }

  pointBetween(scale, other) {
    let zeroed = this.minus(other)
    let scaled = zeroed.factor(scale)
    return scaled.plus(other)
  }

  withAlpha(opacity) {
    return `rgba(${Math.floor(this.red)}, ${Math.floor(
      this.green
    )}, ${Math.floor(this.blue)}, ${opacity})`
  }

  toString() {
    return `rgb(${Math.floor(this.red)}, ${Math.floor(
      this.green
    )}, ${Math.floor(this.blue)})`
  }
}

export class rgbScale {
  constructor(rgbArray, startpoint, endpoint) {
    this.start = startpoint
    this.end = endpoint
    this.array = rgbArray
  }

  get(scale) {
    let len = this.array.length
    let s = this.start
    let e = this.end
    let x = clip(scale, s, e)
    x = ((scale - s) / (e - s)) * (len - 1)

    let top = Math.ceil(x)
    let bot = Math.floor(x)
    if (top === bot) {
      return this.array[top]
    } else {
      return this.array[top].pointBetween(x - bot, this.array[bot])
    }
  }
}

export const stringFilter = (word, allowedCharacters) =>
  Array.from(String(word))
    .filter(c => allowedCharacters.includes(c))
    .join("")

export const customNumberFormat = (x, minval, maxval, oldValue = 0) => {
  /**
   * Accept only period, and digits, all other characters are filtered out.
   *
   * if minus is present in input, minus is present in output, even for 0
   * if 10. then output is 10.
   * if somehow 'NaN' reset to 0
   * if isNaN(Number(x)) revert to oldValue
   */
  const allowedCharacters = "0123456789."
  const minusCount = n => stringFilter(n, "-").length
  const hasMinus = n => minusCount(n) == 1

  const xWithoutPrefix = stringFilter(x, allowedCharacters)

  if (x == "") {
    return 0
  }

  if (x == "NaN") {
    return 0
  }

  if (isNaN(Number(xWithoutPrefix))) {
    return oldValue
  }

  maxval = Number(maxval)
  minval = Number(minval)
  let hasPeriod = String(xWithoutPrefix).includes(".")
  let decimalLength = String(xWithoutPrefix).split(".")[1]?.length
  decimalLength = !decimalLength ? 0 : Math.min(decimalLength, 7)

  let validX = Number(xWithoutPrefix)
  if (hasMinus(String(x))) {
    validX = -validX
  }

  let clippedX = Math.min(Math.max(minval, validX), maxval)

  if (clippedX === maxval) {
    clippedX = maxval
  } else if (clippedX === minval) {
    clippedX = minval
  }

  /**
   * x =>
   * xxx
   * xxx.
   * xxx.0
   * xxx
   *
   * clippedX =>
   * xxx
   * xxx.xx
   * 0.xx
   */
  const prefix = hasMinus(x) && !hasMinus(clippedX) ? "-" : ""
  if (decimalLength > 0) {
    return prefix + String(clippedX.toFixed(decimalLength))
  } else if (hasPeriod) {
    return prefix + String(clippedX) + "."
  } else {
    return prefix + String(clippedX)
  }
}

export const stringMatchesEnding = (word, ending) => {
  return (
    String(word).substring(String(word).length - String(ending).length) ==
    String(ending)
  )
}

export const handleNegativeValue = value =>
  Number(value) < 0 ? NaN : Math.abs(Number(value))

export const handlePlanAndWellData = plantAndWellData => {
  const allowNegativeKeys = [
    "unlevered_pretax_power_cashflow",
    "unlevered_pretax_heat_cashflow_mm",
  ]

  for (const [year, yearData] of Object.entries(plantAndWellData)) {
    for (const [prop, value] of Object.entries(yearData)) {
      if (value < 0 && !allowNegativeKeys.includes(prop)) {
        yearData[prop] = NaN
      } else {
        yearData[prop] = trimZeroAfterPoint(value)
      }
    }
  }
  return plantAndWellData
}

export const isNanForPlantAndWellData = (plantAndWellData, key) => {
  return Object.values(plantAndWellData).some(
    yearData =>
      Object.prototype.hasOwnProperty.call(yearData, key) &&
      isNaN(yearData[key])
  )
}

/**
 * mapObject
 *
 * takes in an object and iterates over its key-value pairs
 *
 * obj is the object to iterate over
 * iteratee is a function that should have the signature:
 * (key, value, obj) => ([key, value] | false)
 *
 * if iteratee returns a [key, value] array pair, then this is added to the output object
 * if iteratee returns a falsey value,t hen the key-value pair is ignored and not added
 */
export const mapObject = (obj, iteratee) => {
  const result = {}
  for (const key in obj) {
    const output = iteratee(key, obj[key], obj)
    if (!!output && Array.isArray(output) && output.length >= 2) {
      result[output[0]] = output[1]
    }
  }
  return result
}
const _mapObject = mapObject

/**
 * OrderedObject
 *
 * A class of JavaScript object that maintains a fixed order to its key-value pairs
 */
export class OrderedObject {
  /**
   * Default constructor to initialize its two properties: data and order
   *
   * data - the JavaScript Object
   * order - the list of keys from data, but ordered as desired
   */
  constructor() {
    this.data = {}
    this.order = []
  }

  static merge(...objs) {
    const result = new OrderedObject()

    const sources = objs.map(obj => {
      if (obj instanceof OrderedObject) {
        return obj
      } else {
        return OrderedObject.createFromObject(obj)
      }
    })

    sources.forEach(source =>
      source.map((key, value) => {
        result.set(key, value)
      })
    )

    return result
  }

  update(...objs) {
    objs.forEach(obj => {
      if (obj instanceof OrderedObject) {
        // If obj is OrderedDict, map through each in order and merge it into *this* OrderedObject
        obj.keys().forEach(key => {
          if (!this.order.includes(key)) {
            this.order.push(key)
          }
        })
        this.data = merge(this.data, obj.data)
      } else {
        // If obj is normal object, map through each in order using Object.entries and merge it into each key-value pair
        Object.keys(obj).forEach(key => {
          if (!this.order.includes(key)) {
            this.order.push(key)
          }
        })
        this.data = merge(this.data, obj)
      }
    })

    return this
  }

  /**
   * Create an OrderedObject given an array (arr) of objects, that each contain an indexing key called (pickKey)
   * @param {*} arr - The array of objects
   * @param {*} pickKey - The key from each object to use as an index
   * @returns
   */
  static pickFromArray(arr, pickKey) {
    const result = new OrderedObject()
    arr.forEach(o => {
      result.order.push(o[pickKey])
      result.data[o[pickKey]] = cloneDeep(o)
    })

    // if (!result.isValid()) {
    //   throw new Error("OrderedDict error: key order does not match in length or identity with object data")
    // }

    return result
  }

  /**
   * Create an OrderedObject from any object, with an optional order array for the ordering of keys.
   * All keys must be present and accounted for in the order array parameter. No duplicates.
   *
   * If the order array is not provided, then the order is determined by the default behavior of Object.keys(obj)
   * @param {*} obj - the object to use as a base
   * @param {*} order - the order of keys
   * @returns
   */
  static createFromObject(obj, order = null) {
    if (!order) {
      order = Object.keys(obj)
    }
    const result = new OrderedObject()
    result.order = cloneDeep(order)
    result.data = cloneDeep(obj)

    // if (!result.isValid()) {
    //   throw new Error("OrderedDict error: key order does not match in length or identity with object data")
    // }

    return result
  }

  /**
   * Copy this OrderedObject
   * @returns a copy of this OrderedObject
   */
  copy() {
    const result = new OrderedObject()
    result.order = cloneDeep(this.order)
    result.data = cloneDeep(this.data)

    return result
  }

  /**
   * Retrieve the keys of this OrderedObject
   * @returns a copy of the keys of this OrderedObject
   */
  keys() {
    return cloneDeep(this.order)
  }

  /**
   * Retrieve the values of this OrderedObject *in order*
   * @returns a an ordered list of values, where each value is a deep copy
   */
  values() {
    return this.order.map(key => cloneDeep(this.data[key]))
  }

  /**
   * Map over the values of this OrderedObject in order
   * @param {*} iteratee - a callback function of (key, value) => newValue
   * @returns an ordered list of values returned by the iteratee after iterating over OrderedObject values
   */
  map(iteratee) {
    return this.order.map(k => iteratee(k, cloneDeep(this.data[k])))
  }

  /**
   * Map over the values of this OrderedObject in order, but returns an OrderedObject
   * @param {*} iteratee - a callback function of (key, value, dataObject) => [newKey, newValue]
   * @returns an ordered list of values returned by the iteratee after iterating over OrderedObject values
   */
  mapObject(iteratee) {
    const result = new OrderedObject()
    result.order = this.keys()

    const dataCopy = this.object()
    for (const key of this.keys()) {
      const output = iteratee(key, dataCopy[key], dataCopy)
      if (!!output && Array.isArray(output) && output.length >= 2) {
        result.data[output[0]] = output[1]
      }
    }

    return result
  }

  /**
   * Retrieve the underlying object data of this OrderedObject
   * @returns a copy of the object data
   */
  object() {
    return cloneDeep(this.data)
  }

  /**
   * Get the index of the given key
   * @param {*} key - key to search for in ordered list
   * @returns index of the key in the order, or -1 if not present
   */
  indexOf(key) {
    return this.order.indexOf(key)
  }

  /**
   * Set or add a new key-value pair to this OrderedObject. If the key is new,
   * then it is appended to the end of the order.
   * @param {*} key - key of the key-value pair to add to this OrderedObject
   * @param {*} value - value of the key-value pair to add to this OrderedObject
   */
  set(key, value) {
    if (!this.order.includes(key)) {
      this.order.push(key)
    }
    this.data[key] = value
  }

  /**
   * Remove a key-value pair from this OrderedObject.
   * Throws an error if the key is not in the OrderedObject.
   *
   * @param {*} key - key of the key-value pair to remove from this OrderedObject
   * @param {*} value - value of the key-value pair to remove from this OrderedObject
   */
  remove(key) {
    const i = this.order.indexOf(key)
    if (i == -1 || key in this.data) {
      throw new Error(`OrderedObject does not contain key: ${key}`)
    }
    this.order.splice(i, 1)
    delete this.data[key]
  }

  /**
   * Checks if ordered keys equals the unordered data keys
   * @returns true if valid, false if invalid
   */
  isValid() {
    const dataKeys = Object.keys(this.data)
    return (
      intersection(this.order, dataKeys).length == this.order.length &&
      this.order.length == dataKeys.length
    )
  }
}

export function toRounded(x, n=0) {
  const d = 10**n
  return Math.round(x * d) / d
}